import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';


const firebaseConfig = {
    apiKey: "AIzaSyDlDaxv-mHYU42-D1Ph8nrtuFNWtrv6y78",
    authDomain: "blackbyrd-404301.firebaseapp.com",
    projectId: "blackbyrd-404301",
    storageBucket: "blackbyrd-404301.appspot.com",
    messagingSenderId: "549572690149",
    appId: "1:549572690149:web:0dffb0be6947b22910140c",
    measurementId: "G-8M1FJ6QT3B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Optionally initialize Firebase Analytics
const analytics = getAnalytics(app);

// Initialize other Firebase services
const auth = getAuth(app);
const firestore = getFirestore(app);

export { app, analytics, auth, firestore };

export const createUserWithEmailAndPassword = async (email, password) => {
    return await auth.createUserWithEmailAndPassword(email, password);
};

export const signInWithEmailAndPassword = async (email, password) => {
    return await auth.signInWithEmailAndPassword(email, password);
};

onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in
    } else {
        // User is signed out
    }
});

export const createUserProfileDocument = async (userAuth, additionalData) => {
    if (!userAuth) return;

    const userRef = firestore.doc(`users/${userAuth.uid}`);
    const snapShot = await userRef.get();

    if (!snapShot.exists) {
        const { email } = userAuth;
        const createdAt = new Date();
        const role = 'user'; // default role

        try {
            await userRef.set({
                email,
                createdAt,
                role,
                ...additionalData
            });
        } catch (error) {
            console.log('Error creating user', error.message);
        }
    }

    return userRef;
};

// if (userRole === 'admin') {
    // Render admin dashboard
// } else {
    // Render regular user dashboard
// }
