import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Firebase initialization is done in firebaseConfig.js
import './firebaseConfig';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Service Worker setup
// Consider updating this to use the newer Workbox-based service worker if you're using Create React App
serviceWorker.unregister(); // or serviceWorker.register(); for offline capabilities
